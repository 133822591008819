import { Link, useI18next } from "gatsby-plugin-react-i18next"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import './language-switch.component.less';
import OutsideClickHandler from "react-outside-click-handler";

const LanguageSwitchComponent = () => {
    const { originalPath } = useI18next()
    const { i18n } = useTranslation()
    let isOpenMenu = false;
    const menu_Lang_dropdown = useRef(null)
    const menu_Lang_triangle = useRef(null)
    function openLanguageMenu () {
        isOpenMenu = !isOpenMenu
        if (isOpenMenu) {
            menu_Lang_dropdown.current.classList.add('lang_selector__dropdown-open')
            menu_Lang_triangle.current.classList.add('lang_selector__triangle-open')
        } else {
            menu_Lang_dropdown.current.classList.remove('lang_selector__dropdown-open');
            menu_Lang_triangle.current.classList.remove('lang_selector__triangle-open');
        }
    }

    function closeLanguageMenu () {
        isOpenMenu = false
        if (menu_Lang_dropdown.current.classList.contains('lang_selector__dropdown-open')) {
            menu_Lang_dropdown.current.classList.remove('lang_selector__dropdown-open');
            menu_Lang_triangle.current.classList.remove('lang_selector__triangle-open');
        }
    }

    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div>
            <div>
                {i18n.language === 'en' && <OutsideClickHandler onOutsideClick={() => closeLanguageMenu()}>
                    {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <div className="lang" onClick={()=>openLanguageMenu()} >
                            <div className="lang_selector" >
                                <div className="lang_selector__flag lang_selector__flag" />
                                <div ref={menu_Lang_triangle} className="lang_selector__triangle"/>
                            </div>
                            <Link to={originalPath} language={'ru'}>
                                <div ref={menu_Lang_dropdown} className="lang_selector lang_selector__dropdown">
                                    <div className="lang_selector__flag lang_selector__flag-ru"/>
                                </div>
                            </Link>
                        </div>
                    </OutsideClickHandler>}
                    {i18n.language === 'ru' && <OutsideClickHandler onOutsideClick={() => closeLanguageMenu()}>
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <div className="lang" onClick={()=>openLanguageMenu()} >
                            <div className="lang_selector" >
                                <div id="menu_Lang_flag" className="lang_selector__flag lang_selector__flag-ru" />
                                <div ref={menu_Lang_triangle} className="lang_selector__triangle"/>
                            </div>
                            <Link to={originalPath} language={'en'}>
                                <div ref={menu_Lang_dropdown} className="lang_selector lang_selector__dropdown">
                                    <div className="lang_selector__flag" />
                                </div>
                            </Link>
                        </div>
                    </OutsideClickHandler>
                    }
                </div>
        </div>
    )
}

LanguageSwitchComponent.propTypes = {}

LanguageSwitchComponent.defaultProps = {}

export default LanguageSwitchComponent
