import { Link } from "gatsby"
import React, {useEffect} from "react"
import { useTranslation } from "react-i18next"
import './header.component.less';
import LanguageSwitchComponent from "../language-switch/language-switch.component";
import OutsideClickHandler from 'react-outside-click-handler';
import {useI18next} from "gatsby-plugin-react-i18next";

const HeaderComponent = () => {
    const { originalPath } = useI18next()
    const { i18n, t } = useTranslation()
    let isOpenMobileMenu = false;
    let isOpenDropDownMenu = false;

    useEffect(() => {
        let idMenu = originalPath
        if (originalPath === '/' || originalPath === '/monitoring-networks/' || originalPath === '/equipment/' || originalPath === '/modeling/' || originalPath === '/software/' || originalPath === '/about-project/' || originalPath === '/documentation/' || originalPath === '/contacts/') {
                     idMenu.slice(1, -1) === '' ? document.getElementById('monitoring-networks').classList.add('menu__dropdown_str-active') : document.getElementById(idMenu.slice(1, -1)).classList.add('menu__dropdown_str-active')
        }
            if (originalPath === '/' || originalPath === '/monitoring-networks/' || originalPath === '/equipment/' || originalPath === '/modeling/' || originalPath === '/software/' || originalPath === '/documentation/') {
                if (!document.getElementById('menu__button').classList.contains('menu__button-active')) {
                    document.getElementById('menu__button').classList.add('menu__button-active')
                }
            } else {
                if (document.getElementById('menu__button').classList.contains('menu__button-active'))
                    document.getElementById('menu__button').classList.remove('menu__button-active')
            }
    });

    function openDropdownMenu () {
        isOpenDropDownMenu = !isOpenDropDownMenu;
        isOpenDropDownMenu ? document.getElementById('arrow_down').classList.add('arrow_down-active') : document.getElementById('arrow_down').classList.remove('arrow_down-active');
        isOpenDropDownMenu ? document.getElementById('menu__dropdown').classList.add('menu__dropdown-active') : document.getElementById('menu__dropdown').classList.remove('menu__dropdown-active');
    }

    function closeDropdownMenu () {
        isOpenDropDownMenu = false;
        if (document.getElementById('arrow_down').classList.contains('arrow_down-active')) document.getElementById('arrow_down').classList.remove('arrow_down-active');
        if (document.getElementById('menu__dropdown').classList.contains('menu__dropdown-active')) document.getElementById('menu__dropdown').classList.remove('menu__dropdown-active');
    }

    function openMobileMenu (menuBtn) {

        isOpenMobileMenu = !isOpenMobileMenu;
        isOpenMobileMenu ? document.getElementById('menu-btn').classList.add('open')
            : document.getElementById('menu-btn').classList.remove('open');

        isOpenMobileMenu ? document.getElementById('background_for_menu').classList.add('background_for_menu-active')
            : document.getElementById('background_for_menu').classList.remove('background_for_menu-active');

        isOpenMobileMenu ? document.getElementById('main_header__container').classList.add('main_header__container-active')
            : document.getElementById('main_header__container').classList.remove('main_header__container-active');
    }
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div>
            <div id="background_for_menu" className="background_for_menu" />
            <div className="mobile-header">
                <div className="mobile-header__line">
                    <Link
                        to="/"
                        language={i18n.language}
                        className="logo logo-mobile"
                    />
                    <div className="mobile-header__right-side">
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <div id="menu-btn" className="menu-btn" onClick={()=>openMobileMenu()}>
                            <div className="menu-btn__burger" />
                        </div>
                    </div>
                </div>  
            </div>  
            

            <div className="main_header">
                <div id="main_header__container" className="container main_header__container">
                    <Link
                        to="/"
                        language={i18n.language}
                        className="logo logo-desktop"
                    />
                    <div className="menu menu-left">
                        <OutsideClickHandler onOutsideClick={() => closeDropdownMenu()}>
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                            <div className="menu__item" onClick={()=>openDropdownMenu()}>
                                <div className="menu__button" id="menu__button">
                                    {t("product")}
                                    <div id="arrow_down" className="arrow_down">
                                        <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.5 7.68046L2.31679 0.402728C1.78679 -0.134243 0.927495 -0.134243 0.397497 0.402728C-0.1325 0.939699 -0.1325 1.8103 0.397498 2.34727L8.54035 10.5973C9.07035 11.1342 9.92965 11.1342 10.4596 10.5973L18.6025 2.34727C19.1325 1.8103 19.1325 0.939698 18.6025 0.402727C18.0725 -0.134244 17.2132 -0.134244 16.6832 0.402727L9.5 7.68046Z" fill="#5D31BF"/>
                                        </svg>
                                    </div>
                                </div>
                                <div id="menu__dropdown" className="menu__dropdown" >
                                    <Link id="monitoring-networks" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/monitoring-networks"}><div className="menu__dropdown_str">{t("monitoringNetworks")}</div></Link>
                                    <Link id="equipment" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/equipment"}><div className="menu__dropdown_str">{t("equipment")}</div></Link>
                                    <Link id="modeling" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/modeling"}><div className="menu__dropdown_str">{t("simulation")}</div></Link>
                                    <Link id="software" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/software"}><div className="menu__dropdown_str">{t("software")}</div></Link>
                                    <Link id="documentation" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/documentation"}><div className="menu__dropdown_str">{t("documentation")}</div></Link>
                                </div>
                            </div>
                        </OutsideClickHandler>
                        <Link id="about-project" onClick={()=>openMobileMenu(false)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/about"}><div className="menu__item">{t("aboutTheCompany")}</div></Link>
                        <Link id="contacts" onClick={()=>openMobileMenu(false)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/contacts"}><div className="menu__item">{t("contacts")}</div></Link>
                    </div>
                    <div className="menu menu-center">
                        <div className="menu__item"><a className="a_arrow_link" title={t("openMap")} href="https://airvoice.ru" target="_blank" rel="noopener noreferrer">{t("map")}<div className="arrow_link" /></a></div>
                        <div className="menu__item"><a className="a_arrow_link" href="https://mycityair.ru" target="_blank" rel="noopener noreferrer">{t("personalAccount")}<div className="arrow_link" /></a></div>
                    </div>
                    {/*<div className="lang_swich_block">
                        <div className="menu menu-right">
                            <LanguageSwitchComponent />
                        </div>
                    </div>*/}
                </div>
            </div>
        </div>
    )
}

HeaderComponent.propTypes = {}

HeaderComponent.defaultProps = {}

export default HeaderComponent
